import { initCWElements, CWEnv } from "@cw-elements/config";
import { getAccessToken } from "core/common-methods";
import { getCurrentEnv } from "core/utils/commonMethods";
import { request } from "api/api";
import { initMfes } from "@cw-elements/mfe-setup/init";

export const initClients = async () => {
  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }

  initCWElements({
    environment: getCurrentEnv() as CWEnv,
    axiosInstance: request,
    token: tokens?.access_token
  });

  await initMfes({
    "invite-members": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/invite-members`,
    "generic-items":  `${process.env.REACT_APP_CW_ELEMENTS_MFES}/generic-items`,
    "seeds-variety":  `${process.env.REACT_APP_CW_ELEMENTS_MFES}/seeds-variety`,
    "crops-catalog": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/crops-catalog`,
    "growth-scales": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/growth-scales`,
    "quota-mgt": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/quota-mgt`,
    "contacts-mgt": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/contacts-mgt`,
    "edit-member": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/edit-member`
  });
};