import React, { Suspense, useEffect } from 'react';
import { Router, Switch, Redirect } from 'react-router';
import styled from 'styled-components';
import get from 'lodash/get';
import { useTranslation } from "react-i18next";
import { useAuth } from './context/auth';
import { history } from 'core/history';
import { Loader } from './components/loader';
import { isAccessTokenAvailable } from 'core/common-methods';
import { initRollout } from 'app-redux/actions/rollout.actions';
import { connect } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import { SentryRoute } from 'sentry.config';

import './main.less';
import './api/api';
import 'syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less';
import { useInitialInfra } from '@cw-elements/rbac-provider';
import { request } from './api/api';

export const StyledRoot = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;

interface IAppComponentPros {
  initRollout?: (configs: any) => Promise<any>;
}

const AsyncMainContainer = React.lazy(() => import('pages/main-container'));

export const App: React.FC<IAppComponentPros> =  (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const ldClient = useLDClient();
  const { initRollout } = props;

  useEffect(() => {
    const isValidAccessToken = isAccessTokenAvailable();
    if (isValidAccessToken && user) {
      if(initRollout) {
        initRollout({ userContext: { id: user.id } });
      }
      if (ldClient) {
        ldClient.identify({ kind: 'user', key: user.id, email: user.email });
      }
    }
  }, [user, ldClient, initRollout]);

  useInitialInfra({ httpReference: request});
  
  if (!user) {
    return null;
  }

  return (
    <StyledRoot>
      <Router history={history}>
        <Suspense fallback={<Loader tip={t('Loading Application...')} />}>
          <Switch>
            <SentryRoute exact path="/app/*" component={AsyncMainContainer} />
            <Redirect from="*" to="/app/home" />
          </Switch>
        </Suspense>
      </Router>
    </StyledRoot>
  );
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch: any) => ({
  initRollout: (configs: any) => dispatch(initRollout(configs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App));
